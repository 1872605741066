import React, {useContext} from "react";
import carouselContext from "../../context/carousel/carouselContext";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CarrouselItem from "./CarrouselItem";

function Carrousel() {
    const { carousel } = useContext(carouselContext);

    return (
        <div id="main-slider">
            {carousel.data && (
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    autoPlay
                    interval={5000}
                    infiniteLoop
                >
                    {carousel.data.map((image, i) => (
                        <div className="item" key={i}>
                            <CarrouselItem image={image} />
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    );
}

export default Carrousel;
