import React from "react";
import { Col } from "react-bootstrap";

const Product = ({ product }) => {
    return (
        <Col md={6} lg={3}>
            <div className="product mt-4">
                <img
                    className="img-fluid"
                    src={product.image}
                    alt={product.title}
                />
            </div>
            <div className="text">
                <h4>{product.title}</h4>
            </div>
        </Col>
    );
};

export default Product;
