import React from "react";
import { Container, Row, Col } from "react-bootstrap";


const Catering = () => {
    return (
        <div id="catering" className="advantages my-4">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                            <h2>Servicio de Catering</h2>
                            <Row className="box">
                                <Col md={7} className="mt-2">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/home/image-catering-home.jpg"
                                        alt="Home Antoñitos"
                                    />
                                </Col>

                                <Col md={5} className="mt-2">
                                    <p className="info">
                                        En Antoñitos encontrarás una amplia
                                        selección de bandejas de salados,
                                        dulces, mini bollería, croissant,
                                        tartas, etc que harán de tus eventos una
                                        delicia. <br />
                                        Servicio a medida de Catering para
                                        empresas, y particulares. <br />
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Catering;
