import React from 'react';


function NoMatch()
{
    return(
         <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 mx-auto">
                    <div className="text-center">
                        <h1 className="mt-5">Página no encontrada</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default NoMatch;