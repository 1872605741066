import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Description() {
    return (
        <div id="box" className="my-4">
            <div className="box">
                <Container>
                    <Row className="box">
                        <Col md={7} className="mt-2">
                            <img
                                className="img-fluid"
                                src="assets/images/home/image-store-view.jpg"
                                alt="Home Antoñitos"
                            />
                        </Col>

                        <Col md={5} className="mt-2">
                            <p className="info">
                                En nuestra panadería hacemos productos de calidad con ingredientes seleccionados, experiencia y arte. <br />Nos preocupamos por la salud de nuestros clientes y trabajadores son lo más importante para nosotros, y hacemos todo con amor y compromiso para que se sientan felices con nuestra panadería. ¡Gracias por confiar en nosotros!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Description;
