import React from "react";
import { Link } from "react-router-dom";

function CarrouselItem({image}) {
    return (
        <>
            {image.url !== null ? (
                <Link to={image.url} style={{ display: "block" }}>
                    <img
                        src={`${image.path}`}
                        alt={image.name}
                        key={image.id}
                    />
                </Link>
            ) : (
                <img
                    src={`${image.path}`}
                    alt={image.name}
                    key={image.id}
                />
            )}
        </>
    );
}

export default CarrouselItem;
