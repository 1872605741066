import React from "react";
import styled from "styled-components";

const ButtonWhatsApp = styled.a`
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25d366;
    color: #fff;
    font-size: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 999;

    &:link,
    &:visited {
        text-decoration: none;
        color: #fff;
    }

    &:hover {
        text-decoration: none;
        color: #fff;
    }

    &:active {
        text-decoration: none;
    }
`;

const WhatsAppButton = () => {
    const handleClick = () => {
        window.location.href = `https://api.whatsapp.com/send?phone=34627915045&text=Hola! Quisiera más información sobre `;
    };
    return (
        <ButtonWhatsApp onClick={handleClick} href="#">
            <i className="fa fa-whatsapp"></i>
        </ButtonWhatsApp>
    );
};

export default WhatsAppButton;
