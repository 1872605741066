import React, { useReducer } from "react";

import clientAxios from "../../config/axios";
import productContext from "./productContext";
import productReducer from "./productReducer";

import { GET_PRODUCT } from "../../types/index";

const ProductState = (props) => {
    const initialState = {
        products: [],
    };

    const [state, dispatch] = useReducer(productReducer, initialState);

    const getProducts = async (search) => {

        try {
            const result = await clientAxios.get("api/v1/products");
            dispatch({ type: GET_PRODUCT, payload: result.data });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <productContext.Provider
            value={{
                products: state.products,
                getProducts,
            }}
        >
            {props.children}
        </productContext.Provider>
    );
};

export default ProductState;
