import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Advantages()
{
    return (
        <div className="advantages">
          <Container>
            <Row className="mb-4">
                <Col md="4">
                    <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                    <div className="icon"><i className="fa fa-heart"></i></div>
                    <h3>Elige salud con nuestros productos</h3>
                    <p className="mb-0">Queremos lo mejor para ti, por eso ofrecemos ingredientes saludables y naturales que cuidan de tu bienestar.</p>
                    </div>
                </Col>

                <Col md="4">
                    <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                    <div className="icon"><i className="fa fa-tags"></i></div>
                    <h3>La auténtica experiencia de panadería, 18 años nos avalan</h3>
                    <p className="mb-0">Nuestra pasión son un homenaje a la tradición de la panadería, hechos con amor.</p>
                    </div>
                </Col>

                <Col md="4">
                    <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                    <div className="icon"><i className="fa fa-thumbs-up"></i></div>
                    <h3>Fabricación propia, calidad asegurada</h3>
                    <p className="mb-0">Elaboramos todos nuestros productos en casa, con ingredientes frescos. Calidad garantizada.</p>
                    </div>
                </Col>
              </Row>
          </Container>
        </div>
    );
}

export default Advantages;