import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Product from "./Product";
import jsonTopProducts from "../../data/top-products.json";

const TopProducts = () => {
    return (
        <div className="advantages my-4">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                            <h2>Productos destacados</h2>
                            <Row>
                                {jsonTopProducts.products.map(product => (
                                    <Product
                                        key={product.uuid}
                                        product={product}
                                    />
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TopProducts;
