import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import styled from 'styled-components';

import Logo from '../assets/images/logo.png';
import LogoSmall from '../assets/images/logo-small.png';

const Styles = styled.div`

    .navbar {
        background-color: #032169;
    }
`

const NavbarMenu = () =>
    (
        <Styles>
            <Navbar collapseOnSelect expand="lg" className="mb-5">
                <div className="container">
                    <LinkContainer to="/">
                        <Navbar.Brand href="/">
                            <img src={Logo} alt="Antoñitos" className="d-none d-md-inline-block" />
                            <img src={LogoSmall} alt="Antoñitos" className="d-inline-block d-md-none" />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">

                        </Nav>

                        <Nav>
                            <LinkContainer to="/productos">
                                <Nav.Item><Nav.Link href="/productos">Productos</Nav.Link></Nav.Item>
                            </LinkContainer>
                            {/*
                                <LinkContainer to="/mini-market">
                                    <Nav.Item><Nav.Link href="/mini-market">MiniMarket</Nav.Link></Nav.Item>
                            </LinkContainer>
                            */}
                            <LinkContainer to="/contacto">
                                <Nav.Item><Nav.Link href="/contacto">Contacto</Nav.Link></Nav.Item>
                            </LinkContainer>
                        </Nav>

                    </Navbar.Collapse>

                </div>
            </Navbar>
        </Styles>

    )


export default NavbarMenu;