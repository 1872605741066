import { GET_MARKET } from "../../types";

export default (state, action) => {
    switch (action.type) {
        case GET_MARKET:
            return {
                ...state,
                market: action.payload,
            };
        default:
            return state;
    }
};
