import React from "react";
import { Link } from "react-router-dom";
import Mailchimp from "./Mailchimp";

const Footer = () => {
    return (
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6"></div>

                    <div className="col-lg-3 col-md-6"></div>

                    <div className="col-lg-3 col-md-6">
                        <h4 className="mb-3">Dónde encontrarnos</h4>
                        <p>
                            <strong>Panadería Antoñitos.</strong>
                            <br />
                            Calle Puerto de Maspalomas, 4<br />
                            28029, Madrid - <strong>España</strong>
                        </p>


                        <Link to="/contacto">Ir a la página de contacto</Link>
                        <hr className="d-block d-md-none" />
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <Mailchimp />

                        <hr />
                        <h4 className="mb-3">Manténgase en contacto</h4>
                        <p className="social">

                            <a href="https://api.whatsapp.com/send?phone=34627915045&text=Hola! Quisiera más información sobre " className="whatsapp external"><i className="fa fa-whatsapp"></i></a>
                            <a
                                href="https://www.facebook.com/antonio.cordero.5473894"
                                className="facebook external"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa fa-facebook"></i>
                            </a>
                            {/*<a href="# " className="twitter external"><i className="fa fa-twitter"></i></a>
                            <a href="# " className="instagram external"><i className="fa fa-instagram"></i></a>
                            <a href="# " className="gplus external"><i className="fa fa-google-plus"></i></a> */}
                            <a
                                href="mailto:antoniomontipan@gmail.com"
                                className="email external"
                            >
                                <i className="fa fa-envelope"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
