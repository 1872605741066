import React from 'react';
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const LawCookies = () => {
    return (
        <CookieConsent
            style={{ background: "rgba(0, 0, 0, 0.755)", textAlign:"center" }}
            cookieName="antonitosCookie"
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            buttonText="Acepto"
        >
            <p style={{ fontSize: "12px", color:"#ccc" }}>
                Recopilamos cookies propias y de terceros con la finalidad de optimizar el rendimiento y la navegación de sus contenidos. <br />
                Consiente la recopilación de cookies &#x1F36A; y el tratamiento de las mismas conforme a nuestra <Link style={{ color:"#fff" }} to="/politica-cookies">politica de cookies</Link>. Si continúas navegando, aceptas su uso.
            </p>

        </CookieConsent>
    );
}

export default LawCookies;