import React, { useReducer } from "react";

import clientAxios from "../../config/axios";
import marketContext from "./marketContext";
import marketReducer from "./marketReducer";

import { GET_MARKET } from "../../types/index";

const MarketState = (props) => {
    const initialState = {
        market: [],
    };

    const [state, dispatch] = useReducer(marketReducer, initialState);

    const getMarket = async (search) => {

        try {
            const result = await clientAxios.get("api/v1/markets?search=" + search);
            dispatch({ type: GET_MARKET, payload: result.data });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <marketContext.Provider
            value={{
                market: state.market,
                getMarket,
            }}
        >
            {props.children}
        </marketContext.Provider>
    );
};

export default MarketState;
