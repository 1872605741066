import { GET_CAROUSEL } from "../../types";

export default (state, action) => {
    switch (action.type) {
        case GET_CAROUSEL:
            return {
                ...state,
                carousel: action.payload,
            };
        default:
            return state;
    }
};
