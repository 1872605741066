import React, { useReducer } from "react";

import clientAxios from "../../config/axios";
import carouselContext from "./carouselContext";
import carouselReducer from "./carouselReducer";

import { GET_CAROUSEL } from "../../types/index";

const CarouselState = (props) => {
    const initialState = {
        carousel: [],
    };

    const [state, dispatch] = useReducer(carouselReducer, initialState);

    const getCarousel = async () => {
        try {
            const result = await clientAxios.get("api/v1/banners");
            dispatch({ type: GET_CAROUSEL, payload: result.data });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <carouselContext.Provider
            value={{
                carousel: state.carousel,
                getCarousel,
            }}
        >
            {props.children}
        </carouselContext.Provider>
    );
};

export default CarouselState;
