import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

const Delivery = () => {
    const ZeroContact = styled.p`
        font-size: 1.5rem;
    `;
    return (
        <div className="advantages my-4">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                            <div className="icon">
                                <i className="fa fa-motorcycle"></i>
                            </div>
                            <h2>Envío a domicilio</h2>
                            <ZeroContact>
                                "GLOVO y UBER EATS"
                            </ZeroContact>
                            <p className="my-4">
                                Puedes contactar con nosotros para realizar tú
                                pedido. <br />
                                Llámanos al 627 91 50 45 en horario de tienda.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Delivery;
