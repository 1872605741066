import React, { createRef } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Link } from "react-router-dom";

const Mailchimp = () => {
    const emailRef = createRef(undefined);
    const url =
        "https://montipan.us4.list-manage.com/subscribe/post?u=25c9edfbbc6625dc0e3a30fc8&id=3795fdad05";
    return (
        <div>
            <h4 className="mb-3">Suscríbete a nuestro boletín</h4>
            <p className="text-muted">
                Si estás interesado en recibir por correo electrónico nuestras
                novedades de productos. Basta con que indiques tu correo
                electrónico a continuación.
            </p>

            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => {
                    switch (status) {
                        case "sending":
                            return <div className="bg-info text-light py-2 text-center">Enviando...</div>;
                        case "success":
                            return <div className="bg-success text-light py-2 text-center">Suscrito correctamente.</div>;
                        case "error":
                            return <div className="bg-danger text-light py-2 text-center">Se ha producido un error al suscribirse.</div>;
                        default:
                            return (
                                <form
                                    onSubmit={event => {
                                        event.preventDefault();

                                        subscribe({
                                            EMAIL: emailRef.current.value
                                        });
                                    }}
                                >
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            ref={emailRef}
                                        />
                                        <span className="input-group-append">
                                            <button
                                                type="submot"
                                                className="btn btn-outline-secondary"
                                            >
                                                Suscríbete!
                                            </button>
                                        </span>
                                    </div>
                                    <p className="text-muted small mt-2">
                                        <Link
                                            to="/politica-privacidad"
                                            className="small"
                                        >
                                            *Al hacer clic en Suscríbete,
                                            confirmas que estás de acuerdo con
                                            el uso de tu información bajo
                                            nuestra política de privacidad.
                                        </Link>
                                    </p>
                                </form>
                            );
                    }
                }}
            />
        </div>
    );
};

export default Mailchimp;
