import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Copyright()
{
    return(
        <div id="copyright">
            <Container>
                <Row>
                    <Col lg={6} className="mb-2 mb-lg-0">
                        <p className="text-center text-lg-left">© {new Date().getFullYear()} Antoñitos.</p>
                    </Col>
                    <Col lg={6}>
                        <p className="text-center text-lg-right">

                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Copyright;