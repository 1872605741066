import React, { Fragment, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";
import ScrollToTop from "react-router-scroll-top";

import CarouselState from "./context/carousel/carouselState";
import MarketState from "./context/market/marketState";
import ProductState from "./context/product/productState";
import CategoryState from "./context/categories/categoryState";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.default.css";
import "./assets/css/custom.css";
import "./index.css";

import Layout from "./components/Layout";
import WhatsAppButton from "./components/WhatsAppButton";
import Home from "./components/Home";
import NavbarMenu from "./components/NavbarMenu";

import NoMatch from "./components/NoMatch";
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import LawCookies from "./components/LawCookies";

ReactGA.initialize("G-H379DJB2X4");
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
});

function App() {
    const Contact = lazy(() => import("./components/Contact"));
    const Products = lazy(() => import("./components/Products"));
    //const MiniMarket = lazy(() => import("./components/Minimarket/MiniMarket"));
    const Franchise = lazy(() => import("./components/Franchise"));
    const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
    const CookiesPolicy = lazy(() => import("./components/CookiesPolicy"));

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    return (
        <CarouselState>
            <MarketState>
                <CategoryState>
                    <ProductState>
                        <Fragment>
                            <Router>
                                <ScrollToTop>
                                    <NavbarMenu />
                                    <Layout>
                                        <Suspense
                                            fallback={
                                                <div className="container">
                                                    <div className="row">
                                                        Cargando...
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <Switch>
                                                <Route
                                                    exact
                                                    path="/"
                                                    component={Home}
                                                />
                                                <Route
                                                    exact
                                                    path="/productos"
                                                    component={Products}
                                                />
                                                {/*
                                                    <Route
                                                        exact
                                                        path="/mini-market"
                                                        component={MiniMarket}
                                                    />
                                                */}
                                                <Route
                                                    exact
                                                    path="/contacto"
                                                    component={Contact}
                                                />
                                                <Route
                                                    exact
                                                    path="/politica-privacidad"
                                                    component={PrivacyPolicy}
                                                />
                                                <Route
                                                    exact
                                                    path="/politica-cookies"
                                                    component={CookiesPolicy}
                                                />
                                                <Route
                                                    exact
                                                    path="/franquicia"
                                                    component={Franchise}
                                                />
                                                <Route component={NoMatch} />
                                            </Switch>
                                        </Suspense>
                                    </Layout>
                                    <WhatsAppButton />
                                    <Footer />
                                    <Copyright />
                                    <LawCookies />
                                </ScrollToTop>
                            </Router>
                        </Fragment>
                    </ProductState>
                </CategoryState>
            </MarketState>
        </CarouselState>
    );
}

export default App;
