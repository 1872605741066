import React, { Fragment, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";

import carouselContext from "../context/carousel/carouselContext";

import Carrousel from "./Home/Carrousel";
import Advantages from "./Home/Advantages";
import TopProducts from "./Home/TopProducts";
import Catering from "./Home/Catering";
import Delivery from "./Home/Delivery";
import Description from "./Home/Description";

function Home() {
    const { carousel, getCarousel } = useContext(carouselContext);

    useEffect(() => {
        if (Object.entries(carousel).length > 0) return;
        getCarousel();
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Container>
                <Carrousel />
            </Container>

            <Advantages />

            <Description />

            <TopProducts />
            <Catering />

            <Delivery />
        </Fragment>
    );
}

export default Home;