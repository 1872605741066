import React, { useReducer } from "react";

import clientAxios from "../../config/axios";
import categoryContext from "./categoryContext";
import categoryReducer from "./categoryReducer";

import { GET_CATEGORY } from "../../types/index";

const CategoryState = (props) => {
    const initialState = {
        categories: [],
    };

    const [state, dispatch] = useReducer(categoryReducer, initialState);

    const getCategories = async (search) => {
        try {
            const result = await clientAxios.get("api/v1/categories");
            dispatch({ type: GET_CATEGORY, payload: result.data });
        } catch (error) {
            console.log(error);
        }
    };
    
    return (
        <categoryContext.Provider
            value={{
                categories: state.categories,
                getCategories,
            }}
        >
            {props.children}
        </categoryContext.Provider>

    );
};

export default CategoryState;
