import React from 'react'


export default function Layout({children}) {
    return (
        <div id="content">
            {children}
        </div>
    )
}
